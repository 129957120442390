import React from 'react';

const IconLogo = () => (
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
  //   <title>Logo</title>
  //   <g transform="translate(-8.000000, -2.000000)">
  //     <g transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="currentColor"
  //       />
  //       <polygon
  //         id="Shape"
  //         stroke="currentColor"
  //         strokeWidth="5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         points="39 0 0 22 0 67 39 90 78 68 78 23"
  //       />
  //     </g>
  //   </g>
  // </svg>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 1040 1040"
    enableBackground="new 0 0 1040 1040"
  >
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <path
        fill="#0a192f"
        opacity="1.000000"
        stroke="none"
        d="
M682.000000,1081.000000 
	C454.692841,1081.000000 227.885681,1081.000000 1.039263,1081.000000 
	C1.039263,721.067749 1.039263,361.135437 1.039263,1.101576 
	C360.890717,1.101576 720.781494,1.101576 1080.836182,1.101576 
	C1080.836182,360.999939 1080.836182,720.999939 1080.836182,1081.000000 
	C948.135010,1081.000000 815.317505,1081.000000 682.000000,1081.000000 
M375.460052,889.000854 
	C365.286285,894.432861 363.378571,903.306519 365.531647,914.373718 
	C378.621613,918.640198 392.033875,920.539307 405.884399,914.735840 
	C411.329102,912.454529 417.384827,911.666016 423.075623,909.915955 
	C424.727814,909.407898 426.626892,908.252136 427.477234,906.832825 
	C431.641449,899.882141 431.586853,892.318848 430.431915,884.526794 
	C429.883545,880.827454 429.744171,876.850830 430.489136,873.219177 
	C432.339050,864.200745 434.157349,855.082458 437.183502,846.421875 
	C441.060272,835.326660 445.788635,824.492249 450.742493,813.820435 
	C455.566193,803.429077 461.799225,793.656128 466.134094,783.086975 
	C468.772095,776.654907 468.988525,769.229736 470.353180,761.927185 
	C620.921631,761.927185 771.335571,761.927185 922.909790,761.927185 
	C920.704041,758.353943 919.075134,755.480347 917.225830,752.756409 
	C901.634705,729.791687 885.970215,706.876587 870.472656,683.848999 
	C869.611206,682.568848 869.228943,679.891174 869.978027,678.764648 
	C879.871338,663.887085 890.005920,649.169922 900.084167,634.415344 
	C907.741638,623.204773 915.404846,611.997925 923.485901,600.174316 
	C822.725586,600.174316 722.696106,600.174316 622.454468,600.174316 
	C636.521912,573.291809 644.690918,541.074768 638.708130,525.078430 
	C639.561646,552.369934 630.601562,576.751953 617.044739,599.998901 
	C589.616211,599.998901 561.955200,599.758789 534.304077,600.175476 
	C527.767517,600.273926 524.586426,598.011414 521.565430,592.474182 
	C518.809204,587.422363 514.839844,582.226318 510.058838,579.274170 
	C502.867798,574.833740 494.497223,572.307556 486.638245,568.944031 
	C475.958771,564.373230 466.945374,557.882568 461.223572,546.516479 
	C457.657349,553.391846 454.241241,559.977722 450.825134,566.563660 
	C450.174255,551.794739 465.337738,506.259460 471.370453,501.647888 
	C481.861389,505.854126 491.980133,510.478668 502.488037,513.912109 
	C508.824463,515.982483 515.759766,516.242249 522.437073,517.241455 
	C531.070129,518.533264 539.714844,519.747131 548.353455,521.002075 
	C558.898071,522.533936 569.706482,523.090454 579.922791,525.843018 
	C594.833740,529.860474 609.505737,530.016541 624.395508,526.960205 
	C632.099731,525.378662 635.494995,521.653076 635.945068,514.176819 
	C636.254639,509.034607 636.353027,503.828979 635.919861,498.704559 
	C635.251526,490.797272 634.270752,482.898987 632.999512,475.065521 
	C631.991089,468.851440 637.485413,459.259155 642.738892,459.011780 
	C650.661682,458.638702 658.664856,458.910034 666.493896,457.862183 
	C677.409912,456.401123 688.690552,455.334351 698.914062,451.619324 
	C711.703186,446.971893 717.398315,444.470276 716.870850,426.485413 
	C716.697327,420.568298 715.313477,414.622101 713.941895,408.817108 
	C710.176025,392.878571 706.531372,376.790619 698.030029,362.561523 
	C692.847351,353.887177 691.321411,344.332153 688.898682,334.869568 
	C685.895142,323.139099 680.514465,312.363617 673.202698,302.672607 
	C669.697876,298.027466 663.884155,297.495514 659.026001,295.167999 
	C645.031372,288.463318 631.642273,280.506653 617.830017,273.402069 
	C613.579651,271.215820 613.667297,267.946533 614.081421,264.380524 
	C614.586304,260.032593 615.485962,255.730499 616.217224,251.394867 
	C623.290344,254.337158 630.652771,249.696747 634.675354,239.816956 
	C637.346436,233.256363 636.591064,228.965958 633.103394,227.490356 
	C633.749634,220.130829 635.039490,212.963272 634.844116,205.836441 
	C634.577026,196.095215 631.831909,186.680145 625.879211,178.836227 
	C621.394226,172.926422 616.320679,166.933899 608.173584,166.033463 
	C599.392456,165.062943 590.525330,164.875366 581.741089,163.926529 
	C575.084839,163.207535 569.455750,165.350067 564.503601,169.298279 
	C561.737488,171.503677 559.624084,174.535797 557.254272,177.228592 
	C549.553040,185.979431 545.275940,196.083954 544.899658,207.832794 
	C544.812683,210.548569 544.900818,214.658142 543.317505,215.693527 
	C537.018433,219.812637 538.896790,225.163254 540.015076,230.471237 
	C541.601379,238.000656 545.943420,242.674744 551.970886,243.505524 
	C551.970886,253.442764 551.966614,263.424042 551.973694,273.405334 
	C551.975647,276.149628 550.678223,277.217834 548.038452,276.408447 
	C540.528748,274.105835 532.984070,273.777283 525.654053,276.782562 
	C520.314392,278.971832 515.149719,279.136566 509.431061,278.404510 
	C504.632202,277.790192 499.467773,278.842072 494.642487,279.937256 
	C489.130890,281.188202 485.839783,285.573730 482.444916,289.888855 
	C475.376648,298.873138 470.299774,308.563202 468.902679,320.080872 
	C468.066498,326.974457 466.246063,333.463562 461.558868,339.121887 
	C457.195618,344.389160 453.593903,350.318451 449.946777,356.137970 
	C446.979370,360.872955 445.299164,366.697876 441.555328,370.611359 
	C428.985748,383.750458 420.344727,398.980286 413.699951,415.780212 
	C410.959686,422.708435 406.485931,429.329773 401.444458,434.852783 
	C397.184052,439.520111 390.889252,442.296387 385.669983,446.147003 
	C384.410156,447.076477 383.505981,448.837585 383.012634,450.397858 
	C380.466949,458.448792 383.081085,466.153748 385.137115,473.798157 
	C385.574646,475.424835 387.435150,477.280121 389.058563,477.861389 
	C393.833557,479.571045 398.761078,480.991425 403.739075,481.956543 
	C408.171417,482.815887 411.535248,480.593262 414.039948,476.894531 
	C416.801117,472.816986 413.496338,470.874664 411.194977,468.720459 
	C409.644653,467.269318 407.709015,466.229858 405.537018,464.722595 
	C409.506073,462.253021 412.180969,461.006439 414.339447,459.157104 
	C418.594543,455.511353 425.302429,453.900970 425.289001,446.459259 
	C425.287689,445.734375 426.460266,444.900360 427.230286,444.299316 
	C430.824646,441.493530 434.399261,438.654083 438.109406,436.006683 
	C446.882294,429.746643 456.436584,424.310394 462.426819,414.964478 
	C466.004578,409.382416 468.283691,402.919983 472.155670,397.585693 
	C478.009277,389.521301 484.764099,382.112946 491.092712,374.390411 
	C493.554321,371.386658 495.898315,368.286560 498.708801,364.704010 
	C498.106812,375.530182 497.839935,385.399567 496.907227,395.205658 
	C496.345367,401.112885 495.369812,407.130829 493.486847,412.731445 
	C489.456329,424.719330 486.291595,436.726471 489.054749,449.399475 
	C489.991547,453.696106 491.115479,457.328888 485.194489,458.498596 
	C484.663177,458.603577 484.326447,460.025513 484.024475,460.880096 
	C483.605133,462.066803 483.612579,463.527557 482.903595,464.469147 
	C477.475281,471.678619 471.829559,478.725098 466.438293,485.961487 
	C463.881958,489.392639 460.141602,493.059387 465.895355,496.544037 
	C464.443817,499.259155 462.916382,501.519073 461.967621,504.000275 
	C458.841125,512.176575 456.165039,520.531311 452.860260,528.631592 
	C449.409393,537.090149 446.236389,545.519897 446.970123,554.871521 
	C447.284149,558.873962 448.461456,563.002686 447.838409,566.845703 
	C446.392517,575.764038 443.312408,584.463867 442.397461,593.406006 
	C441.747437,599.758972 438.974945,600.092834 433.909241,600.085999 
	C344.586121,599.965698 255.262833,599.998962 165.939560,599.998962 
	C164.014862,599.998962 162.090179,599.998901 160.165482,599.998901 
	C159.757965,600.465027 159.350433,600.931152 158.942902,601.397217 
	C176.951309,627.930786 194.959717,654.464294 212.982132,681.018433 
	C195.795929,706.361633 178.616592,731.666748 161.500854,757.014832 
	C160.627869,758.307739 160.301529,759.969727 159.436707,762.188354 
	C243.201508,762.188354 325.873627,762.188354 408.558624,762.188354 
	C407.347198,775.210144 405.999329,787.788330 405.049072,800.396484 
	C403.860199,816.170959 403.217133,831.987915 401.932068,847.753357 
	C400.693176,862.952393 394.759216,875.734070 381.406219,884.262207 
	C379.481140,885.491699 377.782166,887.075073 375.460052,889.000854 
z"
      />
      <path
        fill="#F1A283"
        opacity="1.000000"
        stroke="none"
        d="
M375.719299,888.747803 
	C377.782166,887.075073 379.481140,885.491699 381.406219,884.262207 
	C394.759216,875.734070 400.693176,862.952393 401.932068,847.753357 
	C403.217133,831.987915 403.860199,816.170959 405.049072,800.396484 
	C405.999329,787.788330 407.347198,775.210144 408.558624,762.188354 
	C325.873627,762.188354 243.201508,762.188354 159.436707,762.188354 
	C160.301529,759.969727 160.627869,758.307739 161.500854,757.014832 
	C178.616592,731.666748 195.795929,706.361633 212.982132,681.018433 
	C194.959717,654.464294 176.951309,627.930786 158.942902,601.397217 
	C159.350433,600.931152 159.757965,600.465027 160.165482,599.998901 
	C162.090179,599.998901 164.014862,599.998962 165.939560,599.998962 
	C255.262833,599.998962 344.586121,599.965698 433.909241,600.085999 
	C438.974945,600.092834 441.747437,599.758972 442.397461,593.406006 
	C443.312408,584.463867 446.392517,575.764038 447.838409,566.845703 
	C448.461456,563.002686 447.284149,558.873962 446.970123,554.871521 
	C446.236389,545.519897 449.409393,537.090149 452.860260,528.631592 
	C456.165039,520.531311 458.841125,512.176575 461.967621,504.000275 
	C462.916382,501.519073 464.443817,499.259155 465.895355,496.544037 
	C460.141602,493.059387 463.881958,489.392639 466.438293,485.961487 
	C471.829559,478.725098 477.475281,471.678619 482.903595,464.469147 
	C483.612579,463.527557 483.605133,462.066803 484.024475,460.880096 
	C484.326447,460.025513 484.663177,458.603577 485.194489,458.498596 
	C491.115479,457.328888 489.991547,453.696106 489.054749,449.399475 
	C486.291595,436.726471 489.456329,424.719330 493.486847,412.731445 
	C495.369812,407.130829 496.345367,401.112885 496.907227,395.205658 
	C497.839935,385.399567 498.106812,375.530182 498.708801,364.704010 
	C495.898315,368.286560 493.554321,371.386658 491.092712,374.390411 
	C484.764099,382.112946 478.009277,389.521301 472.155670,397.585693 
	C468.283691,402.919983 466.004578,409.382416 462.426819,414.964478 
	C456.436584,424.310394 446.882294,429.746643 438.109406,436.006683 
	C434.399261,438.654083 430.824646,441.493530 427.230286,444.299316 
	C426.460266,444.900360 425.287689,445.734375 425.289001,446.459259 
	C425.302429,453.900970 418.594543,455.511353 414.339447,459.157104 
	C412.180969,461.006439 409.506073,462.253021 405.537018,464.722595 
	C407.709015,466.229858 409.644653,467.269318 411.194977,468.720459 
	C413.496338,470.874664 416.801117,472.816986 414.039948,476.894531 
	C411.535248,480.593262 408.171417,482.815887 403.739075,481.956543 
	C398.761078,480.991425 393.833557,479.571045 389.058563,477.861389 
	C387.435150,477.280121 385.574646,475.424835 385.137115,473.798157 
	C383.081085,466.153748 380.466949,458.448792 383.012634,450.397858 
	C383.505981,448.837585 384.410156,447.076477 385.669983,446.147003 
	C390.889252,442.296387 397.184052,439.520111 401.444458,434.852783 
	C406.485931,429.329773 410.959686,422.708435 413.699951,415.780212 
	C420.344727,398.980286 428.985748,383.750458 441.555328,370.611359 
	C445.299164,366.697876 446.979370,360.872955 449.946777,356.137970 
	C453.593903,350.318451 457.195618,344.389160 461.558868,339.121887 
	C466.246063,333.463562 468.066498,326.974457 468.902679,320.080872 
	C470.299774,308.563202 475.376648,298.873138 482.444916,289.888855 
	C485.839783,285.573730 489.130890,281.188202 494.642487,279.937256 
	C499.467773,278.842072 504.632202,277.790192 509.431061,278.404510 
	C515.149719,279.136566 520.314392,278.971832 525.654053,276.782562 
	C532.984070,273.777283 540.528748,274.105835 548.038452,276.408447 
	C550.678223,277.217834 551.975647,276.149628 551.973694,273.405334 
	C551.966614,263.424042 551.970886,253.442764 551.970886,243.505524 
	C545.943420,242.674744 541.601379,238.000656 540.015076,230.471237 
	C538.896790,225.163254 537.018433,219.812637 543.317505,215.693527 
	C544.900818,214.658142 544.812683,210.548569 544.899658,207.832794 
	C545.275940,196.083954 549.553040,185.979431 557.254272,177.228592 
	C559.624084,174.535797 561.737488,171.503677 564.503601,169.298279 
	C569.455750,165.350067 575.084839,163.207535 581.741089,163.926529 
	C590.525330,164.875366 599.392456,165.062943 608.173584,166.033463 
	C616.320679,166.933899 621.394226,172.926422 625.879211,178.836227 
	C631.831909,186.680145 634.577026,196.095215 634.844116,205.836441 
	C635.039490,212.963272 633.749634,220.130829 633.103394,227.490356 
	C636.591064,228.965958 637.346436,233.256363 634.675354,239.816956 
	C630.652771,249.696747 623.290344,254.337158 616.217224,251.394867 
	C615.485962,255.730499 614.586304,260.032593 614.081421,264.380524 
	C613.667297,267.946533 613.579651,271.215820 617.830017,273.402069 
	C631.642273,280.506653 645.031372,288.463318 659.026001,295.167999 
	C663.884155,297.495514 669.697876,298.027466 673.202698,302.672607 
	C680.514465,312.363617 685.895142,323.139099 688.898682,334.869568 
	C691.321411,344.332153 692.847351,353.887177 698.030029,362.561523 
	C706.531372,376.790619 710.176025,392.878571 713.941895,408.817108 
	C715.313477,414.622101 716.697327,420.568298 716.870850,426.485413 
	C717.398315,444.470276 711.703186,446.971893 698.914062,451.619324 
	C688.690552,455.334351 677.409912,456.401123 666.493896,457.862183 
	C658.664856,458.910034 650.661682,458.638702 642.738892,459.011780 
	C637.485413,459.259155 631.991089,468.851440 632.999512,475.065521 
	C634.270752,482.898987 635.251526,490.797272 635.919861,498.704559 
	C636.353027,503.828979 636.254639,509.034607 635.945068,514.176819 
	C635.494995,521.653076 632.099731,525.378662 624.395508,526.960205 
	C609.505737,530.016541 594.833740,529.860474 579.922791,525.843018 
	C569.706482,523.090454 558.898071,522.533936 548.353455,521.002075 
	C539.714844,519.747131 531.070129,518.533264 522.437073,517.241455 
	C515.759766,516.242249 508.824463,515.982483 502.488037,513.912109 
	C491.980133,510.478668 481.861389,505.854126 471.370453,501.647888 
	C465.337738,506.259460 450.174255,551.794739 450.825134,566.563660 
	C454.241241,559.977722 457.657349,553.391846 461.223572,546.516479 
	C466.945374,557.882568 475.958771,564.373230 486.638245,568.944031 
	C494.497223,572.307556 502.867798,574.833740 510.058838,579.274170 
	C514.839844,582.226318 518.809204,587.422363 521.565430,592.474182 
	C524.586426,598.011414 527.767517,600.273926 534.304077,600.175476 
	C561.955200,599.758789 589.616211,599.998901 617.044739,599.998901 
	C630.601562,576.751953 639.561646,552.369934 638.708130,525.078430 
	C644.690918,541.074768 636.521912,573.291809 622.454468,600.174316 
	C722.696106,600.174316 822.725586,600.174316 923.485901,600.174316 
	C915.404846,611.997925 907.741638,623.204773 900.084167,634.415344 
	C890.005920,649.169922 879.871338,663.887085 869.978027,678.764648 
	C869.228943,679.891174 869.611206,682.568848 870.472656,683.848999 
	C885.970215,706.876587 901.634705,729.791687 917.225830,752.756409 
	C919.075134,755.480347 920.704041,758.353943 922.909790,761.927185 
	C771.335571,761.927185 620.921631,761.927185 470.353180,761.927185 
	C468.988525,769.229736 468.772095,776.654907 466.134094,783.086975 
	C461.799225,793.656128 455.566193,803.429077 450.742493,813.820435 
	C445.788635,824.492249 441.060272,835.326660 437.183502,846.421875 
	C434.157349,855.082458 432.339050,864.200745 430.489136,873.219177 
	C429.744171,876.850830 429.883545,880.827454 430.431915,884.526794 
	C431.586853,892.318848 431.641449,899.882141 427.477234,906.832825 
	C426.626892,908.252136 424.727814,909.407898 423.075623,909.915955 
	C417.384827,911.666016 411.329102,912.454529 405.884399,914.735840 
	C392.033875,920.539307 378.621613,918.640198 365.531647,914.373718 
	C363.378571,903.306519 365.286285,894.432861 375.719299,888.747803 
M646.512634,415.981567 
	C648.009338,408.504486 649.598816,401.044159 650.949829,393.540833 
	C651.392090,391.084656 652.326538,387.620117 651.138489,386.146484 
	C643.745361,376.976807 639.191711,366.530609 636.781677,355.234985 
	C634.953796,346.668030 633.042664,337.820190 633.342957,329.172882 
	C633.723572,318.214325 636.350952,307.338470 637.877197,296.408508 
	C638.046875,295.193512 637.766724,293.155518 636.990845,292.700745 
	C633.714661,290.780487 630.171814,289.315277 626.691711,287.667175 
	C623.726440,300.419464 618.022156,311.137482 608.217163,319.118134 
	C599.084106,326.551849 588.333984,325.361816 577.397583,324.157166 
	C559.258423,322.159180 549.476318,308.880066 546.958435,293.519562 
	C545.098816,282.174500 545.079285,282.082336 534.519958,283.116119 
	C533.127441,283.252472 531.184631,284.656616 530.694946,285.933289 
	C528.555969,291.509155 526.431152,297.155457 525.086792,302.958221 
	C521.834167,316.998199 519.045837,331.145325 516.022461,345.238983 
	C512.960876,359.510986 503.105286,372.056976 505.507629,387.779205 
	C506.092926,391.610016 505.530365,395.715149 504.926178,399.605713 
	C504.010498,405.502075 503.163544,411.511292 501.277710,417.133911 
	C497.137604,429.477570 493.832214,441.714752 497.873810,454.778656 
	C498.338196,456.279663 497.913361,459.446686 497.186462,459.687836 
	C493.790710,460.814240 492.773376,463.351410 491.009644,466.108612 
	C485.762115,474.312103 479.589813,481.925140 473.756927,489.752563 
	C471.472229,492.818604 471.331238,494.989624 475.305206,497.100403 
	C482.049255,500.682465 488.584625,504.367218 496.219086,506.104095 
	C502.368256,507.503113 508.117828,510.576752 514.179321,512.478149 
	C517.087097,513.390259 520.317871,513.232849 523.382202,513.693604 
	C530.420288,514.751953 537.481323,516.987671 544.469299,516.763062 
	C557.555176,516.342407 569.927307,518.741455 582.315186,522.477905 
	C598.470337,527.350647 614.489075,525.368347 630.257263,520.202942 
	C631.006958,519.957336 632.104980,519.366089 632.205139,518.790466 
	C632.641357,516.283081 633.140015,513.693054 632.945496,511.185059 
	C631.890503,497.581940 630.636292,483.994202 629.414246,469.981781 
	C626.506897,471.300873 624.519958,472.689728 622.351257,473.087585 
	C617.419861,473.992249 612.397766,474.792572 607.415649,474.774536 
	C606.057922,474.769653 605.013794,471.208008 603.289795,470.161285 
	C600.586792,468.520203 597.882324,467.423065 600.393250,463.645447 
	C600.399902,463.635498 599.477234,463.105011 599.087036,462.718323 
	C596.576111,460.230011 594.092773,457.713959 591.600342,455.207031 
	C591.904663,454.805054 592.209045,454.403107 592.513367,454.001160 
	C591.363342,454.001160 590.136353,454.261230 589.078918,453.948334 
	C586.701538,453.244995 584.408203,452.257446 582.080688,451.385590 
	C583.383850,449.409576 584.676025,447.426147 585.995789,445.461273 
	C586.511658,444.693268 587.246399,444.035278 587.606934,443.207611 
	C588.368286,441.460052 588.603210,439.392151 589.660645,437.879822 
	C595.410278,429.656830 603.793274,429.770203 612.201721,431.710846 
	C619.852722,433.476654 627.362061,435.509308 635.133789,432.915985 
	C637.367493,432.170624 640.409607,431.361938 641.323486,429.646149 
	C643.467041,425.621796 644.547852,421.031403 646.512634,415.981567 
M301.323456,670.048828 
	C298.597229,653.321411 295.836456,636.599426 293.171387,619.862305 
	C292.578400,616.138123 292.114410,612.227112 287.776611,611.207825 
	C283.194244,610.130981 278.498505,610.700378 275.556305,614.799866 
	C272.972687,618.399719 270.704712,622.393738 269.144836,626.533081 
	C260.348358,649.875793 251.748230,673.293274 243.208603,696.731689 
	C239.736298,706.261963 236.410049,715.853699 233.345810,725.521545 
	C231.106216,732.587646 233.399857,736.558105 240.041229,737.681335 
	C244.577545,738.448486 248.390656,736.902039 250.394928,732.564453 
	C253.392212,726.077820 257.193573,719.699219 258.686035,712.846375 
	C260.614197,703.992859 266.031006,701.562500 273.755127,701.168945 
	C273.920593,701.160583 274.083893,701.116699 274.248993,701.095825 
	C288.229645,699.334839 288.130341,699.351135 290.451874,713.504272 
	C291.525421,720.049072 292.602386,726.669250 294.643066,732.941101 
	C295.428314,735.354431 298.718597,738.260498 301.174225,738.579224 
	C308.788422,739.567566 313.911316,733.214905 312.411743,725.666199 
	C308.783295,707.400879 305.149139,689.136597 301.323456,670.048828 
M332.100525,737.016174 
	C337.454468,736.060181 343.035889,735.769043 348.121613,734.029114 
	C366.692383,727.675598 381.941376,716.756348 391.423492,699.173584 
	C400.807739,681.772278 393.807739,665.121643 375.001801,659.187195 
	C372.017426,658.245422 368.850311,657.809387 365.734894,657.374329 
	C361.627441,656.800659 357.472015,656.568359 353.366638,655.983032 
	C350.094391,655.516479 348.428802,653.550537 348.662567,650.097900 
	C349.021790,644.792175 349.342926,639.482971 349.595795,634.171265 
	C349.847290,628.889038 350.441406,623.568420 350.050232,618.326965 
	C349.720337,613.906799 346.744385,611.172852 342.016388,610.956787 
	C337.146423,610.734192 332.880371,613.432007 331.683533,617.866760 
	C330.865173,620.899414 330.376770,624.051453 330.054047,627.182495 
	C327.803192,649.018250 325.602753,670.859314 323.454559,692.705444 
	C322.608398,701.310364 321.396606,709.929382 321.349274,718.547546 
	C321.307800,726.093140 322.406097,733.788757 332.100525,737.016174 
M504.055847,674.418396 
	C503.705109,669.612549 503.884796,664.679382 502.774872,660.055786 
	C502.237732,657.818420 499.412628,654.781799 497.384247,654.586121 
	C494.741180,654.331177 490.984863,655.823730 489.312683,657.873657 
	C487.394806,660.224731 486.736664,663.958252 486.405426,667.181091 
	C485.470001,676.283020 485.047211,685.436340 484.300537,694.559387 
	C483.418732,705.333862 482.292969,716.089600 481.525787,726.871460 
	C481.294434,730.122620 482.247437,733.289490 485.986389,734.386414 
	C489.838409,735.516418 493.377869,734.857361 495.761688,731.436035 
	C497.751984,728.579651 499.271423,725.399292 501.077911,722.409912 
	C508.128815,710.741882 515.098694,699.021973 522.366638,687.490234 
	C524.018677,684.869080 526.743591,682.924133 528.974670,680.667908 
	C529.523438,680.930786 530.072144,681.193665 530.620911,681.456482 
	C530.620911,684.702393 530.830994,687.964478 530.582764,691.191284 
	C529.728149,702.302734 528.613708,713.395142 527.862122,724.512634 
	C527.743103,726.273071 528.455566,728.731384 529.699768,729.820190 
	C533.223022,732.903015 537.446533,732.283325 541.473511,730.620667 
	C545.183472,729.088867 547.139709,726.433899 547.269348,722.167908 
	C547.516235,714.041748 548.283142,705.932434 548.594116,697.806885 
	C548.992676,687.390869 550.409668,676.802551 549.081543,666.588318 
	C547.618530,655.337097 538.959656,652.115845 529.682922,658.940186 
	C522.958191,663.887146 517.528198,670.611206 511.614380,676.634644 
	C509.474426,678.814270 507.606567,681.260986 505.614563,683.585815 
	C505.212799,683.426758 504.811035,683.267761 504.409302,683.108765 
	C504.289490,680.530457 504.169708,677.952209 504.055847,674.418396 
M570.973511,720.500488 
	C582.904968,733.830261 601.645752,737.613525 618.476746,730.090027 
	C624.897278,727.220032 627.312012,722.646790 625.057983,717.625610 
	C623.175171,713.431152 619.864868,712.723877 612.988770,715.826233 
	C606.103882,718.932556 599.332397,718.593262 592.683167,715.738098 
	C590.256836,714.696228 588.366455,712.406128 585.282837,709.920776 
	C588.746643,708.785034 590.476501,708.248108 592.185791,707.652344 
	C602.237305,704.149353 612.326904,700.748962 622.315735,697.075012 
	C627.943787,695.004883 631.563782,689.985413 630.691895,684.255798 
	C628.735352,671.398071 623.850342,659.644897 610.920166,654.509766 
	C598.258301,649.481018 586.286865,652.719482 576.160889,661.731750 
	C557.398743,678.430359 559.610657,704.221985 570.973511,720.500488 
M765.090881,697.633240 
	C772.011230,694.839111 775.879150,690.153870 774.784546,683.750854 
	C772.572021,670.808350 767.531372,658.939880 754.152283,654.170471 
	C741.588989,649.691895 729.775879,652.910461 719.988647,662.013184 
	C708.951721,672.278259 705.233948,684.961243 706.894348,700.004944 
	C710.160278,729.595520 741.473877,741.470520 764.895630,728.971863 
	C769.596252,726.463501 771.123657,722.035767 769.106018,717.648743 
	C767.258789,713.632385 764.200806,713.154236 758.438599,715.068298 
	C754.259094,716.456726 749.764099,717.984131 745.499023,717.759033 
	C739.795105,717.458008 733.584534,716.550415 730.618225,709.924561 
	C742.233398,705.804688 753.314087,701.874390 765.090881,697.633240 
M435.970795,733.794067 
	C444.437012,734.434875 452.579041,733.210632 460.221130,729.392578 
	C465.540863,726.734741 467.464203,722.102844 465.298920,717.534485 
	C463.425110,713.581177 460.113556,712.946838 454.055450,715.382690 
	C453.283752,715.693054 452.503632,715.985962 451.747375,716.330688 
	C445.169281,719.330139 438.658905,718.244629 432.429901,715.498657 
	C430.173676,714.503967 428.496246,712.196472 426.240814,710.211731 
	C439.038086,705.599731 450.627258,701.515564 462.143402,697.235046 
	C468.190765,694.987366 471.767578,690.277100 470.933136,684.244324 
	C469.198486,671.703003 464.314545,660.435242 452.119659,654.896362 
	C440.147705,649.458801 428.327148,652.126892 418.380524,660.119812 
	C403.942322,671.722107 400.083160,687.251587 404.042938,705.009460 
	C407.773163,721.737671 419.354340,729.974609 435.970795,733.794067 
M668.099426,679.593811 
	C659.641846,688.983521 650.407593,697.800781 642.945923,707.924622 
	C635.540955,717.971558 639.312317,724.450012 651.697327,725.371338 
	C658.832397,725.902222 665.970581,726.602661 673.115906,726.723877 
	C680.074097,726.841980 687.112183,726.832153 693.984558,725.907349 
	C699.038513,725.227295 700.783142,721.742493 700.267883,716.761353 
	C699.770569,711.954224 696.664978,710.323303 692.360291,710.226746 
	C689.032776,710.152100 685.695557,710.376770 682.373169,710.229980 
	C676.522583,709.971680 670.680054,709.531372 663.437256,709.080200 
	C666.053772,705.749756 667.491821,703.552368 669.288818,701.704041 
	C673.815552,697.048157 678.479553,692.524231 683.135193,687.995361 
	C687.432190,683.815308 691.902161,679.807251 696.087097,675.519592 
	C699.779907,671.736145 701.177429,666.519592 698.940369,662.171814 
	C697.558167,659.485596 692.946289,657.161926 689.660950,656.986511 
	C677.566711,656.340942 665.420349,656.582886 653.295227,656.684875 
	C651.020996,656.703979 648.695618,657.434875 646.497131,658.153198 
	C642.814209,659.356506 641.516602,662.104675 641.656189,665.917786 
	C641.797729,669.782532 644.290222,671.345886 647.392395,671.664734 
	C653.337219,672.275513 659.335144,672.370667 665.311279,672.674927 
	C668.055176,672.814636 670.799561,672.942932 674.955322,673.145020 
	C672.145386,675.780334 670.376404,677.439453 668.099426,679.593811 
M822.949402,661.390381 
	C818.446899,663.655273 813.944458,665.920166 808.765198,668.525513 
	C808.765198,663.496643 809.002014,659.709412 808.711548,655.963074 
	C808.236389,649.836182 804.629395,647.216736 798.566345,648.209595 
	C793.112305,649.102783 790.822083,652.044373 790.123657,660.565063 
	C788.371704,681.938232 786.872375,703.332153 785.269836,724.717590 
	C784.783936,731.202393 786.817810,734.076233 792.557312,735.001465 
	C799.895020,736.184326 804.236023,733.971680 805.163940,727.375122 
	C806.341187,719.005920 807.451721,710.453918 806.972290,702.073364 
	C806.487061,693.589600 809.854614,688.432678 816.901306,685.011292 
	C821.365234,682.844116 825.953735,680.886902 830.614807,679.187622 
	C834.194153,677.882751 838.060547,677.367615 841.644836,676.073486 
	C847.436951,673.982300 850.803101,668.911194 849.803040,664.413208 
	C848.885864,660.288574 843.622375,657.048279 837.695251,657.803955 
	C832.959106,658.407837 828.347900,659.990906 822.949402,661.390381 
M672.058350,419.374847 
	C676.350220,417.850006 675.714600,414.575592 674.186340,411.768097 
	C668.426575,401.187225 662.383850,390.760345 656.089233,379.643890 
	C654.994751,388.605438 654.415161,396.868408 652.879761,404.949799 
	C651.295471,413.288513 648.781189,421.450500 646.621826,429.892731 
	C656.119812,430.157135 663.504150,424.259216 672.058350,419.374847 
z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
M646.285767,416.325836 
	C644.547852,421.031403 643.467041,425.621796 641.323486,429.646149 
	C640.409607,431.361938 637.367493,432.170624 635.133789,432.915985 
	C627.362061,435.509308 619.852722,433.476654 612.201721,431.710846 
	C603.793274,429.770203 595.410278,429.656830 589.660645,437.879822 
	C588.603210,439.392151 588.368286,441.460052 587.606934,443.207611 
	C587.246399,444.035278 586.511658,444.693268 585.995789,445.461273 
	C584.676025,447.426147 583.383850,449.409576 582.080688,451.385590 
	C584.408203,452.257446 586.701538,453.244995 589.078918,453.948334 
	C590.136353,454.261230 591.363342,454.001160 592.513367,454.001160 
	C592.209045,454.403107 591.904663,454.805054 591.600342,455.207031 
	C594.092773,457.713959 596.576111,460.230011 599.087036,462.718323 
	C599.477234,463.105011 600.399902,463.635498 600.393250,463.645447 
	C597.882324,467.423065 600.586792,468.520203 603.289795,470.161285 
	C605.013794,471.208008 606.057922,474.769653 607.415649,474.774536 
	C612.397766,474.792572 617.419861,473.992249 622.351257,473.087585 
	C624.519958,472.689728 626.506897,471.300873 629.414246,469.981781 
	C630.636292,483.994202 631.890503,497.581940 632.945496,511.185059 
	C633.140015,513.693054 632.641357,516.283081 632.205139,518.790466 
	C632.104980,519.366089 631.006958,519.957336 630.257263,520.202942 
	C614.489075,525.368347 598.470337,527.350647 582.315186,522.477905 
	C569.927307,518.741455 557.555176,516.342407 544.469299,516.763062 
	C537.481323,516.987671 530.420288,514.751953 523.382202,513.693604 
	C520.317871,513.232849 517.087097,513.390259 514.179321,512.478149 
	C508.117828,510.576752 502.368256,507.503113 496.219086,506.104095 
	C488.584625,504.367218 482.049255,500.682465 475.305206,497.100403 
	C471.331238,494.989624 471.472229,492.818604 473.756927,489.752563 
	C479.589813,481.925140 485.762115,474.312103 491.009644,466.108612 
	C492.773376,463.351410 493.790710,460.814240 497.186462,459.687836 
	C497.913361,459.446686 498.338196,456.279663 497.873810,454.778656 
	C493.832214,441.714752 497.137604,429.477570 501.277710,417.133911 
	C503.163544,411.511292 504.010498,405.502075 504.926178,399.605713 
	C505.530365,395.715149 506.092926,391.610016 505.507629,387.779205 
	C503.105286,372.056976 512.960876,359.510986 516.022461,345.238983 
	C519.045837,331.145325 521.834167,316.998199 525.086792,302.958221 
	C526.431152,297.155457 528.555969,291.509155 530.694946,285.933289 
	C531.184631,284.656616 533.127441,283.252472 534.519958,283.116119 
	C545.079285,282.082336 545.098816,282.174500 546.958435,293.519562 
	C549.476318,308.880066 559.258423,322.159180 577.397583,324.157166 
	C588.333984,325.361816 599.084106,326.551849 608.217163,319.118134 
	C618.022156,311.137482 623.726440,300.419464 626.691711,287.667175 
	C630.171814,289.315277 633.714661,290.780487 636.990845,292.700745 
	C637.766724,293.155518 638.046875,295.193512 637.877197,296.408508 
	C636.350952,307.338470 633.723572,318.214325 633.342957,329.172882 
	C633.042664,337.820190 634.953796,346.668030 636.781677,355.234985 
	C639.191711,366.530609 643.745361,376.976807 651.138489,386.146484 
	C652.326538,387.620117 651.392090,391.084656 650.949829,393.540833 
	C649.598816,401.044159 648.009338,408.504486 646.285767,416.325836 
z"
      />
      <path
        fill="#053839"
        opacity="1.000000"
        stroke="none"
        d="
M301.420380,670.460388 
	C305.149139,689.136597 308.783295,707.400879 312.411743,725.666199 
	C313.911316,733.214905 308.788422,739.567566 301.174225,738.579224 
	C298.718597,738.260498 295.428314,735.354431 294.643066,732.941101 
	C292.602386,726.669250 291.525421,720.049072 290.451874,713.504272 
	C288.130341,699.351135 288.229645,699.334839 274.248993,701.095825 
	C274.083893,701.116699 273.920593,701.160583 273.755127,701.168945 
	C266.031006,701.562500 260.614197,703.992859 258.686035,712.846375 
	C257.193573,719.699219 253.392212,726.077820 250.394928,732.564453 
	C248.390656,736.902039 244.577545,738.448486 240.041229,737.681335 
	C233.399857,736.558105 231.106216,732.587646 233.345810,725.521545 
	C236.410049,715.853699 239.736298,706.261963 243.208603,696.731689 
	C251.748230,673.293274 260.348358,649.875793 269.144836,626.533081 
	C270.704712,622.393738 272.972687,618.399719 275.556305,614.799866 
	C278.498505,610.700378 283.194244,610.130981 287.776611,611.207825 
	C292.114410,612.227112 292.578400,616.138123 293.171387,619.862305 
	C295.836456,636.599426 298.597229,653.321411 301.420380,670.460388 
M272.954437,666.466431 
	C271.455688,672.147217 269.956940,677.828003 268.105133,684.847046 
	C273.222839,684.069946 276.958008,683.699524 280.576324,682.838257 
	C281.721161,682.565674 283.436615,680.939148 283.391815,680.004028 
	C282.975098,671.309875 282.031311,662.673157 277.506439,654.334167 
	C276.054810,658.122192 274.603210,661.910156 272.954437,666.466431 
z"
      />
      <path
        fill="#063939"
        opacity="1.000000"
        stroke="none"
        d="
M331.689850,736.935303 
	C322.406097,733.788757 321.307800,726.093140 321.349274,718.547546 
	C321.396606,709.929382 322.608398,701.310364 323.454559,692.705444 
	C325.602753,670.859314 327.803192,649.018250 330.054047,627.182495 
	C330.376770,624.051453 330.865173,620.899414 331.683533,617.866760 
	C332.880371,613.432007 337.146423,610.734192 342.016388,610.956787 
	C346.744385,611.172852 349.720337,613.906799 350.050232,618.326965 
	C350.441406,623.568420 349.847290,628.889038 349.595795,634.171265 
	C349.342926,639.482971 349.021790,644.792175 348.662567,650.097900 
	C348.428802,653.550537 350.094391,655.516479 353.366638,655.983032 
	C357.472015,656.568359 361.627441,656.800659 365.734894,657.374329 
	C368.850311,657.809387 372.017426,658.245422 375.001801,659.187195 
	C393.807739,665.121643 400.807739,681.772278 391.423492,699.173584 
	C381.941376,716.756348 366.692383,727.675598 348.121613,734.029114 
	C343.035889,735.769043 337.454468,736.060181 331.689850,736.935303 
M372.178741,679.327454 
	C366.508301,674.879211 359.842316,673.944763 352.912933,673.625427 
	C347.941589,673.396362 344.745697,675.466248 344.097015,680.300781 
	C342.449341,692.581177 341.122345,704.904541 339.522552,718.431824 
	C355.993561,712.564270 367.724854,703.468384 375.199310,689.306641 
	C377.071442,685.759521 375.347290,682.528687 372.178741,679.327454 
z"
      />
      <path
        fill="#063939"
        opacity="1.000000"
        stroke="none"
        d="
M504.052887,674.896118 
	C504.169708,677.952209 504.289490,680.530457 504.409302,683.108765 
	C504.811035,683.267761 505.212799,683.426758 505.614563,683.585815 
	C507.606567,681.260986 509.474426,678.814270 511.614380,676.634644 
	C517.528198,670.611206 522.958191,663.887146 529.682922,658.940186 
	C538.959656,652.115845 547.618530,655.337097 549.081543,666.588318 
	C550.409668,676.802551 548.992676,687.390869 548.594116,697.806885 
	C548.283142,705.932434 547.516235,714.041748 547.269348,722.167908 
	C547.139709,726.433899 545.183472,729.088867 541.473511,730.620667 
	C537.446533,732.283325 533.223022,732.903015 529.699768,729.820190 
	C528.455566,728.731384 527.743103,726.273071 527.862122,724.512634 
	C528.613708,713.395142 529.728149,702.302734 530.582764,691.191284 
	C530.830994,687.964478 530.620911,684.702393 530.620911,681.456482 
	C530.072144,681.193665 529.523438,680.930786 528.974670,680.667908 
	C526.743591,682.924133 524.018677,684.869080 522.366638,687.490234 
	C515.098694,699.021973 508.128815,710.741882 501.077911,722.409912 
	C499.271423,725.399292 497.751984,728.579651 495.761688,731.436035 
	C493.377869,734.857361 489.838409,735.516418 485.986389,734.386414 
	C482.247437,733.289490 481.294434,730.122620 481.525787,726.871460 
	C482.292969,716.089600 483.418732,705.333862 484.300537,694.559387 
	C485.047211,685.436340 485.470001,676.283020 486.405426,667.181091 
	C486.736664,663.958252 487.394806,660.224731 489.312683,657.873657 
	C490.984863,655.823730 494.741180,654.331177 497.384247,654.586121 
	C499.412628,654.781799 502.237732,657.818420 502.774872,660.055786 
	C503.884796,664.679382 503.705109,669.612549 504.052887,674.896118 
z"
      />
      <path
        fill="#063939"
        opacity="1.000000"
        stroke="none"
        d="
M570.753662,720.221680 
	C559.610657,704.221985 557.398743,678.430359 576.160889,661.731750 
	C586.286865,652.719482 598.258301,649.481018 610.920166,654.509766 
	C623.850342,659.644897 628.735352,671.398071 630.691895,684.255798 
	C631.563782,689.985413 627.943787,695.004883 622.315735,697.075012 
	C612.326904,700.748962 602.237305,704.149353 592.185791,707.652344 
	C590.476501,708.248108 588.746643,708.785034 585.282837,709.920776 
	C588.366455,712.406128 590.256836,714.696228 592.683167,715.738098 
	C599.332397,718.593262 606.103882,718.932556 612.988770,715.826233 
	C619.864868,712.723877 623.175171,713.431152 625.057983,717.625610 
	C627.312012,722.646790 624.897278,727.220032 618.476746,730.090027 
	C601.645752,737.613525 582.904968,733.830261 570.753662,720.221680 
M608.855530,684.259460 
	C609.573059,682.739563 611.200562,680.951294 610.845581,679.747620 
	C609.524597,675.267273 606.685608,671.762695 601.838501,670.672913 
	C590.524109,668.129028 579.312561,680.352905 582.939758,692.740784 
	C591.377258,690.067932 599.810791,687.396301 608.855530,684.259460 
z"
      />
      <path
        fill="#053839"
        opacity="1.000000"
        stroke="none"
        d="
M764.742798,697.788696 
	C753.314087,701.874390 742.233398,705.804688 730.618225,709.924561 
	C733.584534,716.550415 739.795105,717.458008 745.499023,717.759033 
	C749.764099,717.984131 754.259094,716.456726 758.438599,715.068298 
	C764.200806,713.154236 767.258789,713.632385 769.106018,717.648743 
	C771.123657,722.035767 769.596252,726.463501 764.895630,728.971863 
	C741.473877,741.470520 710.160278,729.595520 706.894348,700.004944 
	C705.233948,684.961243 708.951721,672.278259 719.988647,662.013184 
	C729.775879,652.910461 741.588989,649.691895 754.152283,654.170471 
	C767.531372,658.939880 772.572021,670.808350 774.784546,683.750854 
	C775.879150,690.153870 772.011230,694.839111 764.742798,697.788696 
M734.044434,690.545776 
	C739.894409,688.781738 745.852417,687.304626 751.557800,685.156616 
	C755.614868,683.629211 756.193115,680.565735 753.777588,676.752014 
	C749.731567,670.363647 744.002747,668.760010 736.570557,672.264099 
	C727.534973,676.524292 723.993286,683.644714 727.052612,691.997314 
	C729.076599,691.592834 731.159668,691.176575 734.044434,690.545776 
z"
      />
      <path
        fill="#063839"
        opacity="1.000000"
        stroke="none"
        d="
M435.531250,733.755127 
	C419.354340,729.974609 407.773163,721.737671 404.042938,705.009460 
	C400.083160,687.251587 403.942322,671.722107 418.380524,660.119812 
	C428.327148,652.126892 440.147705,649.458801 452.119659,654.896362 
	C464.314545,660.435242 469.198486,671.703003 470.933136,684.244324 
	C471.767578,690.277100 468.190765,694.987366 462.143402,697.235046 
	C450.627258,701.515564 439.038086,705.599731 426.240814,710.211731 
	C428.496246,712.196472 430.173676,714.503967 432.429901,715.498657 
	C438.658905,718.244629 445.169281,719.330139 451.747375,716.330688 
	C452.503632,715.985962 453.283752,715.693054 454.055450,715.382690 
	C460.113556,712.946838 463.425110,713.581177 465.298920,717.534485 
	C467.464203,722.102844 465.540863,726.734741 460.221130,729.392578 
	C452.579041,733.210632 444.437012,734.434875 435.531250,733.755127 
M444.683563,671.601746 
	C436.944061,668.405457 430.977020,672.226318 425.875336,677.135986 
	C421.875549,680.985168 421.245087,686.277100 422.717072,692.281311 
	C429.667267,690.596252 436.221649,689.463501 442.426514,687.319397 
	C445.767395,686.164978 450.538422,683.616455 450.962524,681.078369 
	C451.404510,678.433411 447.393494,675.044250 444.683563,671.601746 
z"
      />
      <path
        fill="#063939"
        opacity="1.000000"
        stroke="none"
        d="
M668.353394,679.346191 
	C670.376404,677.439453 672.145386,675.780334 674.955322,673.145020 
	C670.799561,672.942932 668.055176,672.814636 665.311279,672.674927 
	C659.335144,672.370667 653.337219,672.275513 647.392395,671.664734 
	C644.290222,671.345886 641.797729,669.782532 641.656189,665.917786 
	C641.516602,662.104675 642.814209,659.356506 646.497131,658.153198 
	C648.695618,657.434875 651.020996,656.703979 653.295227,656.684875 
	C665.420349,656.582886 677.566711,656.340942 689.660950,656.986511 
	C692.946289,657.161926 697.558167,659.485596 698.940369,662.171814 
	C701.177429,666.519592 699.779907,671.736145 696.087097,675.519592 
	C691.902161,679.807251 687.432190,683.815308 683.135193,687.995361 
	C678.479553,692.524231 673.815552,697.048157 669.288818,701.704041 
	C667.491821,703.552368 666.053772,705.749756 663.437256,709.080200 
	C670.680054,709.531372 676.522583,709.971680 682.373169,710.229980 
	C685.695557,710.376770 689.032776,710.152100 692.360291,710.226746 
	C696.664978,710.323303 699.770569,711.954224 700.267883,716.761353 
	C700.783142,721.742493 699.038513,725.227295 693.984558,725.907349 
	C687.112183,726.832153 680.074097,726.841980 673.115906,726.723877 
	C665.970581,726.602661 658.832397,725.902222 651.697327,725.371338 
	C639.312317,724.450012 635.540955,717.971558 642.945923,707.924622 
	C650.407593,697.800781 659.641846,688.983521 668.353394,679.346191 
z"
      />
      <path
        fill="#053839"
        opacity="1.000000"
        stroke="none"
        d="
M823.315308,661.264893 
	C828.347900,659.990906 832.959106,658.407837 837.695251,657.803955 
	C843.622375,657.048279 848.885864,660.288574 849.803040,664.413208 
	C850.803101,668.911194 847.436951,673.982300 841.644836,676.073486 
	C838.060547,677.367615 834.194153,677.882751 830.614807,679.187622 
	C825.953735,680.886902 821.365234,682.844116 816.901306,685.011292 
	C809.854614,688.432678 806.487061,693.589600 806.972290,702.073364 
	C807.451721,710.453918 806.341187,719.005920 805.163940,727.375122 
	C804.236023,733.971680 799.895020,736.184326 792.557312,735.001465 
	C786.817810,734.076233 784.783936,731.202393 785.269836,724.717590 
	C786.872375,703.332153 788.371704,681.938232 790.123657,660.565063 
	C790.822083,652.044373 793.112305,649.102783 798.566345,648.209595 
	C804.629395,647.216736 808.236389,649.836182 808.711548,655.963074 
	C809.002014,659.709412 808.765198,663.496643 808.765198,668.525513 
	C813.944458,665.920166 818.446899,663.655273 823.315308,661.264893 
z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
M671.770508,419.642151 
	C663.504150,424.259216 656.119812,430.157135 646.621826,429.892731 
	C648.781189,421.450500 651.295471,413.288513 652.879761,404.949799 
	C654.415161,396.868408 654.994751,388.605438 656.089233,379.643890 
	C662.383850,390.760345 668.426575,401.187225 674.186340,411.768097 
	C675.714600,414.575592 676.350220,417.850006 671.770508,419.642151 
z"
      />
      <path
        fill="#E89E80"
        opacity="1.000000"
        stroke="none"
        d="
M273.053009,666.082275 
	C274.603210,661.910156 276.054810,658.122192 277.506439,654.334167 
	C282.031311,662.673157 282.975098,671.309875 283.391815,680.004028 
	C283.436615,680.939148 281.721161,682.565674 280.576324,682.838257 
	C276.958008,683.699524 273.222839,684.069946 268.105133,684.847046 
	C269.956940,677.828003 271.455688,672.147217 273.053009,666.082275 
z"
      />
      <path
        fill="#EC9F81"
        opacity="1.000000"
        stroke="none"
        d="
M372.453674,679.579712 
	C375.347290,682.528687 377.071442,685.759521 375.199310,689.306641 
	C367.724854,703.468384 355.993561,712.564270 339.522552,718.431824 
	C341.122345,704.904541 342.449341,692.581177 344.097015,680.300781 
	C344.745697,675.466248 347.941589,673.396362 352.912933,673.625427 
	C359.842316,673.944763 366.508301,674.879211 372.453674,679.579712 
z"
      />
      <path
        fill="#E89E80"
        opacity="1.000000"
        stroke="none"
        d="
M608.549927,684.492065 
	C599.810791,687.396301 591.377258,690.067932 582.939758,692.740784 
	C579.312561,680.352905 590.524109,668.129028 601.838501,670.672913 
	C606.685608,671.762695 609.524597,675.267273 610.845581,679.747620 
	C611.200562,680.951294 609.573059,682.739563 608.549927,684.492065 
z"
      />
      <path
        fill="#E89E80"
        opacity="1.000000"
        stroke="none"
        d="
M733.643555,690.653015 
	C731.159668,691.176575 729.076599,691.592834 727.052612,691.997314 
	C723.993286,683.644714 727.534973,676.524292 736.570557,672.264099 
	C744.002747,668.760010 749.731567,670.363647 753.777588,676.752014 
	C756.193115,680.565735 755.614868,683.629211 751.557800,685.156616 
	C745.852417,687.304626 739.894409,688.781738 733.643555,690.653015 
z"
      />
      <path
        fill="#E89E80"
        opacity="1.000000"
        stroke="none"
        d="
M445.006500,671.791138 
	C447.393494,675.044250 451.404510,678.433411 450.962524,681.078369 
	C450.538422,683.616455 445.767395,686.164978 442.426514,687.319397 
	C436.221649,689.463501 429.667267,690.596252 422.717072,692.281311 
	C421.245087,686.277100 421.875549,680.985168 425.875336,677.135986 
	C430.977020,672.226318 436.944061,668.405457 445.006500,671.791138 
z"
      />
    </g>
  </svg>
);

export default IconLogo;
